import {Contract, ContractAbi, HexString, HttpProvider, Web3} from "web3";
import {
  AddressHexStr,
  AmountInWei,
  HexStrStrict,
  IContractAbiFragment, ITxDataSimple,
  ITxTokenBeforeEstimateGas
} from "../../models/chainScan.models";
import {RegisteredSubscription} from "web3-eth";
import {Address, Bytes, Numbers} from "web3-types";

export const toHex = Web3.utils.toHex as (value: Numbers | Bytes | Address | boolean | object, returnType?: boolean) => HexStrStrict
export const {toWei, fromWei, hexToNumberString, toNumber, toBigInt, isAddress, isHexStrict} = Web3.utils

//TODO refactor for approach [.request]
type Extension<T, TypeOutput> = T & {
  request: (...arg: any) => any,
  outputFormatter: TypeOutput
}

export type HexStr = HexString
export const ZERO_HEX = "0x0"

/**
 * By default, the minimum gas unit you must spend on any Ethereum transaction is 21,000.
 */
export const GAS_FOR_SEND_ETHEREUM = 21000
export const GAS_FOR_SEND_ETHEREUM_HEX = toHex(GAS_FOR_SEND_ETHEREUM)

const estimateGasRequest = (dataTx: ITxTokenBeforeEstimateGas) => ({
  id: dataTx.from,
  method: "eth_estimateGas",
  params: [
    {
      "from": dataTx.from,
      "nonce": dataTx.nonce,
      "to": dataTx.to,
      "data": dataTx.data,
    }
  ]
})

const estimateGasRequestForDisperse = (dataTx: ITxDataSimple) => ({
  id: dataTx.from,
  method: "eth_estimateGas",
  params: [
    {
      "from": dataTx.from,
      "to": dataTx.to,
      "data": dataTx.data,
      "value": dataTx.value
    }
  ]
})
const getBalanceRequest = (account: AddressHexStr, prefix = '') => ({
  id: `${prefix.length ? prefix + '.' : prefix}${account}`,
  method: "eth_getBalance",
  params: [
    account,
    "latest"
  ]
})
const getTransactionCountRequest = (account: AddressHexStr, prefix = '') => ({
  id: `${prefix.length ? prefix + '.' : prefix}${account}`,
  method: "eth_getTransactionCount",
  params: [
    account,
    "latest"
  ]
})

function getBalanceOfByContractRequest(this: Contract<ContractAbi>, account: AddressHexStr, prefix = '') {
  return {
    id: `${prefix.length ? prefix + '.' : prefix}${account}`,
    params: [
      {
        from: account,
        data: this.methods.balanceOf(account).encodeABI(),
        to: this.options.address
      },
      "latest"
    ],
    method: "eth_call"
  }
}

const sendSignedTransactionRequest = (rawTx: HexStr, account: AddressHexStr) => ({
  id: `${account}`,
  method: "eth_sendRawTransaction",
  params: [
    rawTx
  ]
})

const getTokenContractInit = function (web3: Web3<RegisteredSubscription>) {
  return (dataContractABI: IContractAbiFragment[], contractAddress: HexStr) => {
    const tokenContract = new web3.eth.Contract(dataContractABI as ContractAbi, contractAddress)

    const request = getBalanceOfByContractRequest.bind(tokenContract)
    const balanceOf = Object.assign(tokenContract.methods.balanceOf, {
      request,
      outputFormatter: (amount: HexStr) => (amount === '0x' ? BigInt('0') : BigInt(amount))
    })
    const transfer = (address: AddressHexStr, value: AmountInWei) => tokenContract.methods.transfer(address, value)

    return {tokenContract, balanceOf, transfer}
  }
}
const getDisperseContractInit = function (web3: Web3<RegisteredSubscription>) {
  return (dataContractABI: IContractAbiFragment[], contractAddress: HexStr) => {
    const disperseContract = new web3.eth.Contract(dataContractABI as ContractAbi, contractAddress)
    const disperseEther = (address: AddressHexStr[], value: any[]) => disperseContract.methods.disperseEther(address, value)

    return {disperseContract, disperseEther}
  }
}

export function setProviderWeb3(linkHttpProvider: string) {
  const web3 = new Web3(new HttpProvider(linkHttpProvider))
  const {BatchRequest} = web3
  const {signTransaction, privateKeyToAccount} = web3.eth.accounts

  const estimateGas = Object.assign(web3.eth.estimateGas.bind(web3.eth), {
    request: estimateGasRequest,
    outputFormatter: BigInt
  })
  const estimateGasForDisperse = Object.assign(web3.eth.estimateGas.bind(web3.eth), {
    request: estimateGasRequestForDisperse,
    outputFormatter: BigInt
  })
  const getBalance = Object.assign(web3.eth.getBalance.bind(web3.eth), {
    request: getBalanceRequest,
    outputFormatter: BigInt
  })
  const getTransactionCount = Object.assign(web3.eth.getTransactionCount.bind(web3.eth), {
    request: getTransactionCountRequest,
    outputFormatter: Number
  })
  const sendSignedTransaction = Object.assign(web3.eth.sendSignedTransaction, {request: sendSignedTransactionRequest})
  const getTokenContract = getTokenContractInit(web3)
  const getDisperseContract = getDisperseContractInit(web3)

  const getChainId = web3.eth.getChainId.bind(web3.eth)
  const getNetworkId = web3.eth.net.getId.bind(web3.eth.net)

  return {
    getNetworkId,
    getChainId,
    estimateGas,
    estimateGasForDisperse,
    getBalance,
    getTransactionCount,
    getTokenContract,
    getDisperseContract,
    sendSignedTransaction,
    signTransaction: signTransaction.bind(web3.eth.accounts),
    getGasPriceInWei: web3.eth.getGasPrice.bind(web3.eth),
    privateKeyToAccount,
    BatchRequest
  }
}

export type Web3InitiatedType = ReturnType<typeof setProviderWeb3>