import {ETHFacade, InitDataType, ITxEthData} from "./ETHFacade";
import {
  AddressType,
  EstimateResultType,
  IAccount,
  IBalanceTokenData,
  IDataForGenerateTransactions,
  IDataForSendTransactions,
  IMapValueByAddress,
  ITokenInfo,
  ITransactionPriorityEnum
} from "../../types";
import {HexStr} from "../../../../store/web3/web3";
import {
  AddressHexStr,
  ApiScanResponse,
  IContractAbiFragment,
  IRpcResponse,
  ITransferEvents,
  ITxTokenBeforeEstimateGas
} from "../../../../models/chainScan.models";
import {JsonRpcError} from "web3-types/src/json_rpc_types";
import ERC20TokenABI from "../../../../store/etherscan/ERC20TokenABI";
import {GasHelper} from "../../../../helpers";
import {IWeb3TokenFacade} from "../IWeb3TokenFacade";
import {ERC20_DEFAULT_IMG} from "../../../../store/etherscan/ERC20Tokens";


export interface IDataForGenerateERC20Transactions extends IDataForGenerateTransactions {
  balanceDataByAddress: IBalanceTokenData,
  transactionPriority: keyof ITransactionPriorityEnum,
  receiverAddress: HexStr
}

export interface ITxErc20Data extends ITxEthData {
  data: HexStr
}

export interface IDataForSendERC20Transactions extends IDataForSendTransactions {
  balanceDataByAddress: IBalanceTokenData,
  privateKeyByAddress: IMapValueByAddress<IAccount['privateKey']>,
  transactionDataByAddress: IMapValueByAddress<ITxErc20Data>,
  transactionPriority: keyof ITransactionPriorityEnum,
  receiverAddress: HexStr
}

class ERC20Facade extends ETHFacade implements IWeb3TokenFacade {
  protected readonly _fetchTokenConf: { apikey: string; url: string };
  protected readonly limitPrivateKeys: number;
  protected readonly addressesChunkSize: number;
  private readonly _abi: IContractAbiFragment[];
  private readonly _defaultTokenImage: string;

  constructor(initData?: {
    baseInitData: InitDataType,
    tokenInitData: {
      abi: IContractAbiFragment[],
      fetchTokenConf: { apikey: string; url: string },
      defaultTokenImage?: string
    },
    limitPrivateKeys?: number,
    addressesChunkSize?: number
  }) {
    super(initData?.baseInitData);

    this._defaultTokenImage = initData?.tokenInitData?.defaultTokenImage || ERC20_DEFAULT_IMG
    this._abi = initData?.tokenInitData?.abi || ERC20TokenABI
    this._fetchTokenConf = initData?.tokenInitData?.fetchTokenConf || {
      apikey: process.env.REACT_APP_PRIVATE_KEY_FOR_ETH_SCAN_API,
      url: process.env.REACT_APP_LINK_FOR_ETH_SCAN_API
    }

    this.limitPrivateKeys = initData?.limitPrivateKeys || 10000;
    this.addressesChunkSize = initData?.addressesChunkSize || 800;
  }

  get defaultTokenImage(): string {
    return this._defaultTokenImage
  }

  getLimitPrivateKeys(tokenAddress?: string): number {
    if (!tokenAddress) return super.getLimitPrivateKeys()
    return this.limitPrivateKeys;
  }

  getAddressesChunkSize(tokenAddress?: string): number {
    if (!tokenAddress) return super.getAddressesChunkSize()
    return this.addressesChunkSize;
  }

  async fetchBalanceDataByAddress(addressList: Set<AddressType>, tokenAddress?: string): Promise<IBalanceTokenData> {
    const balanceTokenByAddress: IBalanceTokenData['balanceTokenByAddress'] = new Map();

    if (tokenAddress) {
      if (addressList.size > this.getAddressesChunkSize(tokenAddress)) {
        throw new Error(`Address size should be lower than ${this.getAddressesChunkSize(tokenAddress)}`);
      }

      const {
        BatchRequest,
        getTokenContract,
      } = this._web3Provider

      const {balanceOf} = getTokenContract(this._abi, tokenAddress!);

      const batchInfo = new BatchRequest();
      addressList.forEach(address => {
        batchInfo.add(balanceOf.request(address as AddressHexStr))
      })
      const dataInfo = await batchInfo.execute({timeout: 30000})

      for (let item of dataInfo) {
        if (item.error) {
          const errorData = item.error as JsonRpcError
          throw new Error(errorData.message + `[${errorData.code}]`)
        }
        let itemSuccess = item as IRpcResponse

        balanceTokenByAddress.set(itemSuccess.id, balanceOf.outputFormatter(itemSuccess.result))
      }
    }
    const {balanceByAddress} = await super.fetchBalanceDataByAddress(addressList)
    return {balanceByAddress, balanceTokenByAddress}
  }

  async generateTransactions(data: IDataForGenerateERC20Transactions, tokenAddress?: AddressType): Promise<EstimateResultType> {
    if (!tokenAddress) return super.generateTransactions(data)

    const {balanceDataByAddress, transactionPriority, receiverAddress} = data
    const {getTokenContract} = this._web3Provider

    const txDataForEstimateByAddress: IMapValueByAddress<ITxErc20Data> = new Map()
    const {balanceTokenByAddress} = balanceDataByAddress

    const gasPriceInWei = await this._fetchGasPriceInWei(transactionPriority)
    const nonceByAddress = await this.__fetchNonce(new Set(balanceTokenByAddress.keys()))
    const chainId = await this._web3Provider.getChainId()
    const networkId = await this._web3Provider.getNetworkId()


    const {transfer} = getTokenContract(this._abi, tokenAddress!);


    balanceTokenByAddress.forEach((balanceToken, address) => {
      const accountHex: AddressHexStr = address as AddressHexStr
      if (accountHex.toLowerCase() === receiverAddress?.toLowerCase()) {
        return
      }

      if (balanceToken > 0) {
        txDataForEstimateByAddress.set(accountHex, {
          from: accountHex,
          to: tokenAddress,
          chainId: chainId,
          networkId: networkId,
          gasPrice: gasPriceInWei,
          value: BigInt(0),
          gas: BigInt(0),
          nonce: BigInt(nonceByAddress.get(address)!),
          data: transfer(receiverAddress as AddressHexStr, balanceToken.toString()).encodeABI()
        })
      }
    })

    return await this._estimateFee(txDataForEstimateByAddress, gasPriceInWei)
  }

  async sendTransactions(data: IDataForSendERC20Transactions, tokenAddress?: AddressType): Promise<IMapValueByAddress> {
    if (!tokenAddress) return super.sendTransactions(data)

    const balanceData = data.balanceDataByAddress
    let {balanceTokenByAddress, balanceByAddress} = balanceData
    balanceTokenByAddress = new Map(Array.from(balanceTokenByAddress).filter(([k, balanceToken]) => balanceToken > BigInt(0)))
    balanceByAddress = new Map(Array.from(balanceByAddress).filter(([address, balance]) => balanceTokenByAddress.has(address)))

    data.balanceDataByAddress = {
      balanceByAddress,
      balanceTokenByAddress
    }
    return super.sendTransactions(data)
  }

  async fetchTokenInfo(tokenAddress: string): Promise<ITokenInfo> {

    const params = {
      module: 'account',
      action: 'tokentx',
      contractaddress: tokenAddress,
      apikey: this._fetchTokenConf.apikey,
      page: '1',
      offset: '1'
    }
    const data: ApiScanResponse<ITransferEvents[]> = await fetch(
      this._fetchTokenConf.url + '/?' + new URLSearchParams(params),
      {cache: "force-cache"}
    ).then(response => response.json())

    if (parseInt(data?.status || '0') === 0 && !data.result) {
      throw new Error(data.message)
    }

    if (parseInt(data?.status || '0') === 1 && data.result.length) {
      const dataToken = data.result[0]

      return {
        symbol: dataToken.tokenSymbol,
        title: dataToken.tokenName,
        address: tokenAddress,
        decimal: Number(dataToken.tokenDecimal),
        img: this.tokensDict[dataToken?.tokenSymbol]?.img || this.defaultTokenImage
      };
    }
    throw new Error("Something was wrong...");
  }

  protected async _estimateFee(txDataForEstimateByAddress: IMapValueByAddress<ITxErc20Data>, gasPriceInWei: bigint) {
    const feeDataByAddress: IMapValueByAddress<BigInt> = new Map()
    const txDataByAddress: IMapValueByAddress<ITxErc20Data> = new Map()

    console.log('erc20')
    const {BatchRequest, estimateGas} = this._web3Provider


    const batchGasEstimate = new BatchRequest();
    txDataForEstimateByAddress.forEach((txData, address) => {
      batchGasEstimate.add(estimateGas.request(this._toHexTxData(txData) as ITxTokenBeforeEstimateGas))
    })

    const gasArr = await batchGasEstimate.execute({timeout: 30000})
    for (let item of gasArr) {
      if (item.error) {
        const errorData = item.error as JsonRpcError
        throw new Error(errorData.message + `[${errorData.code}]`)
      }
      let itemSuccess = item as IRpcResponse
      const originGas = estimateGas.outputFormatter(itemSuccess.result)
      const gas = GasHelper.gasPay(originGas)

      txDataByAddress.set(itemSuccess.id, {
        ...txDataForEstimateByAddress.get(itemSuccess.id)!,
        gas: gas,
      })
      feeDataByAddress.set(itemSuccess.id, gasPriceInWei * gas)
    }

    return {txDataByAddress, feeDataByAddress}
  }
}

export {ERC20Facade};