import {IWeb3DisperseFacade} from "./IWeb3DisperseFacade";
import {ETH_DisperseFacade} from "./ETH_DisperseFacade";
import {
  BscDisperseABI,
  BscDisperseContractAddress,
  BscTestnetDisperseContractAddress
} from "../../../store/bscscan/BEP20DisperseABI";
import {AddressHexStr, IContractAbiFragment} from "../../../models/chainScan.models";

class BSC_DisperseFacade extends ETH_DisperseFacade implements IWeb3DisperseFacade {
  protected _disperseContractABI: IContractAbiFragment[]
  protected _disperseContractAddress: AddressHexStr

  constructor() {
    super({
      web3HttpProviderLink: process.env.REACT_APP_BSC_WEB3_HTTP_PROVIDER,
      network: 'bsc',
      linkForTxScan: process.env.REACT_APP_LINK_FOR_TX_BSC_SCAN,
      defaultTransactionPriority: "high",
      fetchGasPriceConf: {
        apikey: process.env.REACT_APP_PRIVATE_KEY_FOR_BSC_SCAN_API,
        url: process.env.REACT_APP_LINK_FOR_BSC_GAS_PRICE_API
      },
    })

    this._disperseContractAddress = process.env.REACT_APP_ENVIRONMENT === 'dev' ?
      BscTestnetDisperseContractAddress : BscDisperseContractAddress
    this._disperseContractABI = BscDisperseABI
  }
}

export {BSC_DisperseFacade}