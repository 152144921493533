export function* partitionArrayIntoChunks<T>(arr: T[], n: number): Generator<T[], void> {
  for (let i = 0; i < arr.length; i += n) {
    yield arr.slice(i, i + n);
  }
}

export function* partitionMapIntoChunks<T, V>(map: Map<T, V>, n: number): Generator<Map<T, V>, void> {
  const mapAsArray = Array.from(map);
  for (let i = 0; i < map.size; i += n) {
    yield new Map<T, V>(mapAsArray.slice(i, i + n));
  }
}