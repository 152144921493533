import {createBrowserRouter, createRoutesFromElements, Navigate, Route, RouterProvider} from "react-router-dom";
import {Layout} from "../../layout";
import {routePath} from "../../constants/routes";
import {FactoryConsolidationTool, FactoryDisperseTool, GasTrackerPage} from "../../pages";
import * as Sentry from "@sentry/react";

export const Router = () => {
  const sentryCreateBrowserRouter = Sentry.wrapCreateBrowserRouter(
    createBrowserRouter,
  );

  const router = sentryCreateBrowserRouter(createRoutesFromElements(
    <Route element={<Layout/>}>
      <Route path={routePath.gas_tracker} element={<GasTrackerPage/>}/>
      <Route path={routePath.consolidation_tool + '/:network'} element={<FactoryConsolidationTool/>}/>
      <Route path={routePath.disperse_tool + '/:network'} element={<FactoryDisperseTool/>}/>
      <Route path="*" element={<Navigate replace to={routePath.consolidation_tool + '/eth'}/>}/>
    </Route>
  ))

  return (
    <RouterProvider router={router}/>
  )
}