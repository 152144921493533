import {AddressHexStr, IContractAbiFragment, Uint256} from "../../models/chainScan.models";

/**
 * more info by next links
 * https://etherscan.io/address/0xD152f549545093347A162Dce210e7293f1452150#code
 * https://disperse.app/
 * https://disperse.app/disperse.pdf
 */
const EthereumDisperseContractAddress = "0xD152f549545093347A162Dce210e7293f1452150" as const
const EthereumDisperseABI: IContractAbiFragment[] = [{
  "constant": false,
  "inputs": [{"name": "token", "type": "address"}, {"name": "recipients", "type": "address[]"}, {
    "name": "values",
    "type": "uint256[]"
  }],
  "name": "disperseTokenSimple",
  "outputs": [],
  "payable": false,
  "stateMutability": "nonpayable",
  "type": "function"
}, {
  "constant": false,
  "inputs": [{"name": "token", "type": "address"}, {"name": "recipients", "type": "address[]"}, {
    "name": "values",
    "type": "uint256[]"
  }],
  "name": "disperseToken",
  "outputs": [],
  "payable": false,
  "stateMutability": "nonpayable",
  "type": "function"
}, {
  "constant": false,
  "inputs": [{"name": "recipients", "type": "address[]"}, {"name": "values", "type": "uint256[]"}],
  "name": "disperseEther",
  "outputs": [],
  "payable": true,
  "stateMutability": "payable",
  "type": "function"
}]

interface IEthereumDisperse {
  disperseEther(recipients: AddressHexStr[], values: Uint256[]): boolean,
}

export {EthereumDisperseABI, EthereumDisperseContractAddress}