import * as bitcoin from 'bitcoinjs-lib';
import {AddressType, PrivateKeyType} from "../../pages/ConsolidationTool/types";
import {dogecoin} from 'coininfo';
import {ECPairAPI, ECPairFactory} from "ecpair";
import ecc from '@bitcoinerlab/secp256k1';
import {ApiVersion, CurrentUtxoFee, Dogecoin, JsonRpcResponse, Network, TatumSDK} from "@tatumio/tatum";
import {DogeAddressBalanceInfo, DogeUTXOResponse} from "../dogescan/types";
import {ITxDogeData} from "../../pages/ConsolidationTool/facades/DOGE_Network/DOGEFacade";
import {decode} from "wif";

export function setProviderWeb3(apiKey: string, environment: string) {
  const dogecoinNetwork = environment === 'dev' ? dogecoin.test.toBitcoinJS() : dogecoin.main.toBitcoinJS();

  function isValidDogecoinAddress(address: AddressType): boolean {
    try {
      bitcoin.address.toOutputScript(address, dogecoinNetwork);
      return true;
    } catch (error) {
      return false;
    }
  }

  function getAddressByPrivateKey(privateKey: PrivateKeyType): AddressType {
    const ECPair: ECPairAPI = ECPairFactory(ecc);

    const decoded = decode(privateKey);

    const keyPair = ECPair.fromPrivateKey(Buffer.from(decoded.privateKey), {
      network: dogecoinNetwork
    });

    const { address } = bitcoin.payments.p2pkh({ pubkey: keyPair.publicKey, network: dogecoinNetwork });

    return address!;
  }

  async function getBatchBalanceData(addresses: Array<AddressType>): Promise<Array<DogeAddressBalanceInfo>> {
    try {
      return await fetch(`${process.env.REACT_APP_LINK_FOR_DOGECHAIN_API_V3}/address/balance/batch?addresses=${addresses.join(',')}`, {
        headers: {
          "x-api-key": apiKey
        }
      })
        .then(response => response.json());
    } catch (error) {
      throw error;
    }
  }

  async function getRawTransaction(txData: ITxDogeData, fee: number): Promise<string> {
    const ECPair: ECPairAPI = ECPairFactory(ecc);
    const decoded = decode(txData.privateKey);
    const keyPair = ECPair.fromPrivateKey(Buffer.from(decoded.privateKey), {
      network: dogecoinNetwork
    });

    const tatum = await TatumSDK.init<Dogecoin>({
      network: environment === 'dev' ? Network.DOGECOIN_TESTNET : Network.DOGECOIN,
      apiKey: {
        v4: apiKey
      }
    });

    const psbt = new bitcoin.Psbt({network: dogecoinNetwork});
    for (const utxo of txData.utxosData.utxos) {

      const prevTx = await tatum.rpc.getRawTransaction(utxo.txHash);
      psbt.addInput({
        hash: utxo.txHash,
        index: utxo.index,
        nonWitnessUtxo: Buffer.from(prevTx.result, 'hex')
      });
    }

    psbt.addOutput({
      address: txData.receiverAddress,
      value: Number(txData.amount - BigInt(Math.floor(fee)))
    });

    psbt.signAllInputs(keyPair);
    psbt.finalizeAllInputs();

    return psbt.extractTransaction().toHex();
  }

  async function getCurrentFee(): Promise<CurrentUtxoFee> {
    const tatum = await TatumSDK.init<Dogecoin>({
      network: environment === 'dev' ? Network.DOGECOIN_TESTNET : Network.DOGECOIN,
      version: ApiVersion.V3,
      apiKey: {
        v3: apiKey
      }
    });
    try {
      const result = await tatum.fee.getCurrentFee();
      // @ts-ignore
      let feeData = result.data;
      return result.data;
    } catch (error) {
      throw error;
    }
  }

  async function getUTXOsForAddresses(addresses: Array<AddressType>): Promise<Array<DogeUTXOResponse>> {
    const chain = environment === 'dev' ? "doge-testnet" : "doge-mainnet";
    return await fetch(`${process.env.REACT_APP_LINK_FOR_DOGECHAIN_API_V4}/data/utxos/batch`, {
      headers: {
        "x-api-key": apiKey,
        "accept": 'application/json',
        "content-type": "application/json"
      },
      method: 'POST',
      body: JSON.stringify({
        chain,
        addresses,
        /**
         * Only UTXOs up to this amount will be returned.
         * So, use any large number to get all of them
         */
        totalValue: 999999999
      })
    })
      .then(response => response.json());
  }

  async function sendRawDogeTransaction(txData: ITxDogeData, fee: number): Promise<JsonRpcResponse<string>> {
    const tatum = await TatumSDK.init<Dogecoin>({
      network: environment === 'dev' ? Network.DOGECOIN_TESTNET : Network.DOGECOIN,
      apiKey: {
        v4: apiKey
      }
    });
    try {
      const rawTxHex = await getRawTransaction(txData, fee);
      return await tatum.rpc.sendRawTransaction(rawTxHex);
    } catch (error) {
      throw error;
    }
  }

  return {
    isValidDogecoinAddress,
    getAddressByPrivateKey,
    getBatchBalanceData,
    getCurrentFee,
    getUTXOsForAddresses,
    getRawTransaction,
    sendRawDogeTransaction
  }
}

export type Web3DogeType = ReturnType<typeof setProviderWeb3>