import {ITokenDict, NetworkCurrencyEnum} from "../../pages/ConsolidationTool/types";
import bscEmpty from '../../assets/images/bsc-empty-token.svg'

const BEP20_DEFAULT_IMG = bscEmpty as string

const BEP20TestnetTokens: ITokenDict = {
  [NetworkCurrencyEnum.bsc]: {
    "title": "Binance Coin",
    "img": "https://bscscan.com/assets/bsc/images/svg/logos/token-light.svg",
    "rate": "$405.00",
    "address": undefined,
    "link": "https://bscscan.com/",
    "group": `Base Currency (${NetworkCurrencyEnum.bsc})`,
    "website": "https://www.bnbchain.org/",
    "desc": "",
    "decimal": 18,
    "symbol": NetworkCurrencyEnum.bsc
  },
  BUSD: {
    "title": "Binance-Peg BUSD Token",
    "img": "https://bscscan.com/token/images/busd_32_2.png",
    "rate": "$1.01",
    "address": "0x78867BbEeF44f2326bF8DDd1941a4439382EF2A7",
    "link": "https://testnet.bscscan.com/token/0x78867bbeef44f2326bf8ddd1941a4439382ef2a7#code",
    "group": "Testnet Tokens (BEP 20)",
    "website": "",
    "desc": "",
    "decimal": 18,
    "symbol": "BUSD"
  },
  CTT: {
    "title": "CT Token (custom)",
    "img": "https://bscscan.com/assets/bsc/images/svg/logos/token-light.svg",
    "rate": "$0",
    "address": "0xF44160104a941e90165804d36a124787A8a6fed7",
    "link": "https://testnet.bscscan.com/token/0xf44160104a941e90165804d36a124787a8a6fed7#balances",
    "group": "Testnet Tokens (BEP 20)",
    "website": "",
    "desc": "",
    "decimal": 18,
    "symbol": "CTT"
  },
}

export {BEP20TestnetTokens, BEP20_DEFAULT_IMG}