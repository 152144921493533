import {IWeb3Facade} from "../IWeb3Facade";
import {fromWei, HexStr, toWei} from "../../../../store/web3/web3";
import {
  IAccount,
  IBalanceData,
  IDataForGenerateTransactions,
  IDataForSendTransactions,
  IMapValueByAddress,
  ITransactionPriorityEnum
} from "../../types";
import {ETHFacade, InitDataType, ITxEthData} from "../ETH_Network/ETHFacade";
import {BASETokens} from "../../../../store/basescan/BASETokens";
import {createPublicClient, http} from "viem";
import {base, baseSepolia} from "viem/chains";
import {publicActionsL2} from "viem/op-stack";
import {GasHelper} from "../../../../helpers";
import {BASETestnetTokens} from "../../../../store/basescan/BASETestnetTokens";
import {IGasPrice, IGasPriceResult} from "../../../../models/chainScan.models";


interface IDataForGenerateBSCTransactions extends IDataForGenerateTransactions {
  balanceDataByAddress: IBalanceData,
  transactionPriority: keyof ITransactionPriorityEnum,
  receiverAddress: HexStr
}

interface IDataForSendBSCTransactions extends IDataForSendTransactions {
  balanceDataByAddress: IBalanceData,
  privateKeyByAddress: IMapValueByAddress<IAccount['privateKey']>,
  transactionDataByAddress: IMapValueByAddress<ITxEthData>,
  transactionPriority: keyof ITransactionPriorityEnum,
  receiverAddress: HexStr
}

export const BaseTransactionPriorityEnum: ITransactionPriorityEnum = {
  slow: "slow",
  average: "average",
  fast: "fast"
} as const

interface IRawGasPriceItem {
  acceptance: number,
  gasPrice: number | string,
  estimatedFee: number
}

interface IBaseGasPrice {
  slow: bigint,
  average: bigint,
  fast: bigint
}

export const BaseInitData: InitDataType = {
  defaultTransactionPriority: BaseTransactionPriorityEnum.average,
  transactionPriorityOptions: {
    [BaseTransactionPriorityEnum.slow]: "Slow",
    [BaseTransactionPriorityEnum.average]: "Average",
    [BaseTransactionPriorityEnum.fast]: "Fast",
  },
  fetchGasPriceConf: {
    apikey: process.env.REACT_APP_LINK_FOR_BASE_GAS_PRICE_API_KEY,
    url: process.env.REACT_APP_LINK_FOR_BASE_GAS_PRICE_API,
  },
  web3HttpProviderLink: process.env.REACT_APP_BASE_WEB3_HTTP_PROVIDER,
  tokensDict: process.env.REACT_APP_ENVIRONMENT === 'dev' ? BASETestnetTokens : BASETokens,
  network: 'base',
  linkForTxScan: process.env.REACT_APP_LINK_FOR_TX_BASE_SCAN
}

function adapterGasPrice(rawResult: IRawGasPriceItem[]): IBaseGasPrice {
  return {
    slow: BigInt(rawResult[1].gasPrice),
    average: BigInt(rawResult[2].gasPrice),
    fast: BigInt(rawResult[3].gasPrice)
  }
}

class BASEFacade extends ETHFacade implements IWeb3Facade {

  constructor(initData?: InitDataType) {
    super(initData || BaseInitData)
  }

  async _fetchGasPriceInWei(transactionPriority: keyof ITransactionPriorityEnum): Promise<bigint> {
    const response = await fetch(`${this._fetchGasPriceConf.url}?apikey=${this._fetchGasPriceConf.apikey}&eip1559=false&reportwei=true`, {
      method: "GET",
      headers: {"Content-Type": "application/json"},
    });
    const result = await response.json() as {
      timestamp: string,
      lastBlock: number,
      avgTime: number,
      avgTx: number,
      avgGas: number,
      avgL1Fee: number,
      speeds: IRawGasPriceItem[]
    };
    /**
     * In test(dev) env use  web3.eth.getGasPrice() to get actual price for testnet
     */
    let gasPriceResult: IBaseGasPrice = adapterGasPrice(result.speeds)
    if (process.env.REACT_APP_ENVIRONMENT === 'dev') {
      const {getGasPriceInWei} = this._web3Provider
      const slowInWei: bigint = await getGasPriceInWei()
      switch (transactionPriority) {
        case 'slow':
          return GasHelper.gasPricePlusPercent(slowInWei, 10)
        case 'average':
          return GasHelper.gasPricePlusPercent(slowInWei, 50)
        case 'fast':
          return GasHelper.gasPricePlusPercent(slowInWei, 100)
      }
    }

    return gasPriceResult[transactionPriority as keyof IBaseGasPrice] || BigInt(0)
  }

  protected async _estimateFee(txDataForEstimateByAddress: IMapValueByAddress<ITxEthData>, gasPriceInWei: bigint) {
    const {txDataByAddress, feeDataByAddress} = await super._estimateFee(txDataForEstimateByAddress, gasPriceInWei)
    /**
     * Solution for L2 chain
     */
    const publicClient: any = createPublicClient({
      chain: this._environment === 'dev' ? baseSepolia : base,
      transport: http(),
    } as any).extend(publicActionsL2())

    const {done, value} = txDataForEstimateByAddress.values().next()
    if (done) {
      return {txDataByAddress, feeDataByAddress}
    }

    const item = value as ITxEthData
    const feeL1 = await publicClient.estimateL1Fee({
      account: item.from,
      to: item.to,
      value: item.value
    })
    const feeL1More = GasHelper.gasPay(feeL1)
    console.log('base feeL1', feeL1More)

    for (const address of txDataByAddress.keys()) {
      feeDataByAddress.set(address, feeDataByAddress.get(address) + feeL1More)
      const item = txDataByAddress.get(address)
      if (item.value - feeL1More < BigInt(0)) {
        txDataByAddress.delete(address)
      } else {
        item.value = item.value - feeL1More
        txDataByAddress.set(address, item)
      }

    }
    return {txDataByAddress, feeDataByAddress}
  }
}

export {BASEFacade}