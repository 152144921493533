import {ITokenDict, NetworkCurrencyEnum} from "../../pages/ConsolidationTool/types";

const SPL_DEFAULT_IMG = 'none' as const

const SPLDevnetTokens: ITokenDict = {
  [NetworkCurrencyEnum.sol]: {
    "title": "Solana",
    "img": "https://s2.coinmarketcap.com/static/img/coins/64x64/5426.png",
    "rate": "$171.00",
    "address": undefined,
    "link": "https://solscan.io/",
    "group": `Base Currency (${NetworkCurrencyEnum.sol})`,
    "website": "https://faucet.solana.com/",
    "desc": "",
    "decimal": 9,
    "symbol": NetworkCurrencyEnum.sol
  },
  "USDC": {
    "title": "USD Coin",
    "img": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/4zMMC9srt5Ri5X14GAgXhaHii3GnPAEERYPJgZJDncDU/logo.png",
    "rate": "$1",
    "address": '4zMMC9srt5Ri5X14GAgXhaHii3GnPAEERYPJgZJDncDU',
    "link": "https://explorer.solana.com/address/4zMMC9srt5Ri5X14GAgXhaHii3GnPAEERYPJgZJDncDU?cluster=devnet",
    "group": `Tokens (SPL)`,
    "website": "https://faucet.circle.com/",
    "desc": "",
    "decimal": 6,
    "symbol": "USDC"
  },
  "USDC-Dev": {
    "title": "USD Coin dev",
    "img": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/EPjFWdd5AufqSSqeM2qN1xzybapC8G4wEGGkZwyTDt1v/logo.png",
    "rate": "$1",
    "address": 'Gh9ZwEmdLJ8DscKNTkTqPbNwLNNBjuSzaG9Vp2KGtKJr',
    "link": "https://explorer.solana.com/address/Gh9ZwEmdLJ8DscKNTkTqPbNwLNNBjuSzaG9Vp2KGtKJr?cluster=devnet",
    "group": `Tokens (SPL)`,
    "website": "https://spl-token-faucet.com/?token-name=USDC",
    "desc": "",
    "decimal": 6,
    "symbol": "USDC-Dev"
  },
  "EURC": {
    "title": "EURC",
    "img": "https://www.circle.com/hubfs/Brand/EURC/EURC-icon_128x128.png",
    "rate": "$1",
    "address": 'HzwqbKZw8HxMN6bF2yFZNrht3c2iXXzpKcFu7uBEDKtr',
    "link": "https://explorer.solana.com/address/HzwqbKZw8HxMN6bF2yFZNrht3c2iXXzpKcFu7uBEDKtr?cluster=devnet",
    "group": `Tokens (SPL)`,
    "website": "https://faucet.circle.com/",
    "desc": "",
    "decimal": 6,
    "symbol": "EURC"
  },
}

export {SPLDevnetTokens, SPL_DEFAULT_IMG}